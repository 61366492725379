import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Gsec from '../components/logicSec/logicbase'
import Mgsec from '../components/logicSec/mobilelogic/mlogic'
import {wordrobedata} from '../components/logicSec/lgicdata'
import imge from '../images/colorswatch/swing wardrobe/1/w1 (4).jpg'
import {wndata} from '../data/housegdata'

const Kitchen = () => (
  <Layout>
    <SEO title="Wardrobe" />

<Gsec data={wordrobedata} kdata={wndata} imgekt ={imge}/>
<Mgsec data={wordrobedata} kdata={wndata} imgekt ={imge}/>
  </Layout>
)

export default Kitchen